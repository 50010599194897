import { useState } from 'react';
import './App.css';

const poemsObj = {poems: [{title:"", text: ""}]}

function App() {
  const [content, setContent] = useState([]);
  const handleWordChange = (event) => {    
    if (event.target.attributes["answer"].value.toLowerCase() == event.target.value.toLowerCase()) {
      if (!event.target.classList.contains('correct')) {
        event.target.classList.add('correct');
        event.target.classList.remove('wrong');
      }
    } else {
      if (!event.target.classList.contains('wrong')) {
        event.target.classList.remove('correct');
        event.target.classList.add('wrong');
      }
    }
  }
  const handleTextChange = () => {
    const linesArray = document.getElementById("contentInput").value.split("\n");
    const linesContent = [];
    linesArray.forEach((element, index) => {
      if (element !== "") {
        const wordsArray = element.split(" ");
        const wordsContent = [];
        let replaceIndex = 0;
        wordsArray.forEach((element, index1) => {
          if (element !== "") {
            const randNum = Math.floor(Math.random() * wordsArray.length);
            if (index1 === randNum && replaceIndex < 3) {
              wordsContent.push(<span key={index + "_" + index1} ><input type={"text"} answer={element.replace(/[,;!.]$/gi, '')} defaultValue="" size={element.length} onChange={handleWordChange} />&nbsp;</span>)
              replaceIndex++;
            } else {
              wordsContent.push(<span key={index+"_"+index1}>{element}&nbsp;</span>)
            }  
          }
          
        });
        linesContent.push(<div key={index}>{ wordsContent}</div>)
      }
      
    });
    setContent(linesContent);
    document.getElementById("contentInput").value  = "";
  }
  const handleDropdownChange = ()=>{
    const selectedPoemIndex = document.getElementById("poemSelector").value;
    //console.log(selectedPoemIndex.value);
    const poemContent = document.getElementById("poem"+selectedPoemIndex);
    if (poemContent){
      //poemContent = poemContent.replace("<br/>","\r\n");
      console.log(poemContent.innerText);
      document.getElementById("contentInput").value = poemContent.innerHTML.replaceAll("<br>","\r\n");
      
    }else{
      document.getElementById("contentInput").value = "";
    }
    handleTextChange();
    
  }
  return (
    <div className="App">
      <div className='input-content'>
        <select onChange={handleDropdownChange} id="poemSelector">
          <option value={""}>Select poem or enter text below</option>
          <option value={"1"}>Ozymandias</option>
          <option value={"2"}>London</option>
          <option value={"3"}>EXTRACT FROM THE PREDULDE</option>
          <option value={"4"}>My last duchess</option>
          <option value={"5"}>CHARGE OF THE LIGHT BRIGADE</option>
          <option value={"6"}>EXPOSURE</option>
          <option value={"7"}>STORM ON THE ISLAND</option>
          <option value={"8"}>Bayonet charge</option>
          <option value={"9"}>Remains</option>
          <option value={"10"}>Poppies</option>
          <option value={"11"}>War photographer</option>
          <option value={"12"}>Tissue</option>
          <option value={"13"}>THE ÉMIGREE</option>
          <option value={"14"}>CHECKING OUT ME HISTORY</option>
          <option value={"15"}>KAMIKAZE</option>
        </select>
        <textarea rows="20" cols="60" id="contentInput"></textarea>
        <div>
        <button onClick={handleTextChange}>Generate</button>
        </div>
        <div className='poems'>
        <div id="poem1">
        I met a traveller from an antique land <br/>
 Who said: "Two vast and trunkless legs of stone <br/>
 Stand in the desert. Near them, on the sand, <br/>
 Half sunk, a shattered visage lies, whose frown, <br/>
 And wrinkled lip, and sneer of cold command, <br/>
 Tell that its sculptor well those passions read <br/>
 Which yet survive, stamped on these lifeless things, <br/>
 The hand that mocked them and the heart that fed: <br/>
 And on the pedestal these words appear: <br/>
 'My name is Ozymandias, king of kings: <br/>
 Look on my works, ye Mighty, and despair!' <br/>
 Nothing beside remains. Round the decay <br/>
 Of that colossal wreck, boundless and bare <br/>
 The lone and level sands stretch far away."<br/>
        </div>
        <div id="poem2">
        I wander through each chartered street<br/>
Near where the chartered Thames does flow, <br/>
And mark in every face I meet<br/>
Marks of weakness, marks of woe.<br/>
In every cry of every Man,<br/>
In every Infants cry of fear,<br/>
In every voice: in every ban,<br/>
The mind-forged manacles I hear:<br/>
How the Chimney-sweepers cry Every black’ning Church appalls, <br/>
And the hapless Soldiers sigh Runs in blood down Palace walls .<br/>
But most thro' midnight streets I hear<br/>
How the youthful Harlots curse<br/>
Blasts the new-born Infants tear,<br/>
And blights with plagues the Marriage hearse.<br/>
        </div>
        <div id="poem3">
        One summer evening (led by her) I found<br/>
A little boat tied to a willow tree<br/>
Within a rocky cave, its usual home.<br/>
Straight I unloosed her chain, and stepping in Pushed from the shore. 
<br/>It was an act of stealth And troubled pleasure, nor without the voice Of <br/>
mountain-echoes did my boat move on; Leaving behind her still, on either side,<br/>
Small circles glittering idly in the moon,<br/>
Until they melted all into one track<br/>
Of sparkling light. But now, like one who rows, <br/>
Proud of his skill, to reach a chosen point<br/>
With an unswerving line, I fixed my view<br/>
Upon the summit of a craggy ridge,<br/>
The horizon's utmost boundary; far above<br/>
Was nothing but the stars and the grey sky.<br/>
She was an elfin pinnace; lustily<br/>
I dipped my oars into the silent lake,<br/>
And, as I rose upon the stroke, my boat<br/>
Went heaving through the water like a swan; <br/>
When, from behind that craggy steep till then<br/>
The horizon's bound, a huge peak, black and huge, <br/>
As if with voluntary power instinct,<br/>
Upreared its head. I struck and struck again<br/>
And growing still in stature the grim shape<br/>
Towered up between me and the stars, and still,<br/>
For so it seemed, with purpose of its own<br/>
And measured motion like a living thing,<br/>
Strode after me. With trembling oars I turned,<br/>
And through the silent water stole my way<br/>
Back to the covert of the willow tree;<br/>
There in her mooring-place I left my bark,--<br/>
And through the meadows homeward went, <br/>
in grave And serious mood; but after I had seen<br/>
That spectacle, for many days, my brain<br/>
Worked with a dim and undetermined sense<br/>
Of unknown modes of being; o'er my thoughts <br/>
There hung a darkness, call it solitude<br/>
Or blank desertion. No familiar shapes<br/>
Remained, no pleasant images of trees,<br/>
Of sea or sky, no colours of green fields;<br/>
But huge and mighty forms, that do not live<br/>
Like living men, moved slowly through the mind<br/>
By day, and were a trouble to my dreams.<br/>
        </div>
        <div id="poem4">
        That’s my last Duchess painted on the wall,<br/>
Looking as if she were alive. I call<br/>
That piece a wonder, now; Fra Pandolf’s hands<br/>
Worked busily a day, and there she stands.<br/>
Will’t please you sit and look at her? I said<br/>
“Fra Pandolf” by design, for never read<br/>
Strangers like you that pictured countenance,<br/>
The depth and passion of its earnest glance,<br/>
But to myself they turned (since none puts by<br/>
The curtain I have drawn for you, but I)<br/>
And seemed as they would ask me, if they durst,<br/>
How such a glance came there; so, not the first<br/>
Are you to turn and ask thus. Sir, ’twas not<br/>
Her husband’s presence only, called that spot<br/>
Of joy into the Duchess’ cheek; perhaps<br/>
Fra Pandolf chanced to say, “Her mantle laps<br/>
Over my lady’s wrist too much,” or “Paint<br/>
Must never hope to reproduce the faint<br/>
Half-flush that dies along her throat.” Such stuff<br/>
Was courtesy, she thought, and cause enough<br/>
For calling up that spot of joy. She had<br/>
A heart—how shall I say?— too soon made glad,<br/>
Too easily impressed; she liked whate’er<br/>
She looked on, and her looks went everywhere.<br/>
Sir, ’twas all one! My favour at her breast,<br/>
The dropping of the daylight in the West,<br/>
The bough of cherries some officious fool<br/>
Broke in the orchard for her, the white mule<br/>
She rode with round the terrace—all and each<br/>
Would draw from her alike the approving speech,<br/>
Or blush, at least. She thanked men—good!<br/>
 but thanked Somehow—I know not how—as if she ranked<br/>
My gift of a nine-hundred-years-old name<br/>
With anybody’s gift. Who’d stoop to blame<br/>
This sort of trifling? Even had you skill<br/>
In speech—which I have not—to make your will<br/>
Quite clear to such an one, and say, “Just this<br/>
Or that in you disgusts me; here you miss,<br/>
Or there exceed the mark”—and if she let<br/>
Herself be lessoned so, nor plainly set<br/>
Her wits to yours, forsooth, and made excuse—<br/>
E’en then would be some stooping; and I choose<br/>
Never to stoop. Oh, sir, she smiled, no doubt,<br/>
Whene’er I passed her; but who passed without<br/>
Much the same smile? This grew; I gave commands; <br/>
Then all smiles stopped together. There she stands<br/>
As if alive. Will’t please you rise? We’ll meet<br/>
The company below, then. I repeat,<br/>
The Count your master’s known munificence<br/>
Is ample warrant that no just pretense<br/>
Of mine for dowry will be disallowed;<br/>
Though his fair daughter’s self, as I avowed<br/>
At starting, is my object. Nay, we’ll go<br/>
Together down, sir. Notice Neptune, though,<br/>
Taming a sea-horse, thought a rarity,<br/>
Which Claus of Innsbruck cast in bronze for me!<br/>
        </div>
        <div id="poem5">
        Half a league, half a league, Half a league onward,<br/>
All in the valley of Death<br/>
Rode the six hundred. “Forward, the Light Brigade! <br/>
Charge for the guns!” he said. Into the valley of Death<br/>
Rode the six hundred. <br/>
II<br/>
“Forward, the Light Brigade!” Was there a man dismayed? <br/>
Not though the soldier knew<br/>
Someone had blundered. Theirs not to make reply, <br/>
Theirs not to reason why, Theirs but to do and die. <br/>
Into the valley of Death Rode the six hundred. 
III <br/>
Cannon to right of them, Cannon to left of them, Cannon in front of them<br/>
Volleyed and thundered; Stormed at with shot and shell, Boldly they rode and well,<br/>
Into the jaws of Death,<br/>
Into the mouth of hell<br/>
Rode the six hundred. IV<br/>
Flashed all their sabres bare, Flashed as they turned in air Sabring the gunners there, Charging an army, while<br/>
All the world wondered. Plunged in the battery-smoke Right through the line they broke; Cossack and Russian<br/>
Reeled from the sabre stroke<br/>
Shattered and sundered. Then they rode back, but not<br/>
Not the six hundred.<br/>
Cannon to right of them, Cannon to left of them, Cannon behind them<br/>
Volleyed and thundered; Stormed at with shot and shell, While horse and hero fell.<br/>
They that had fought so well Came through the jaws of Death, Back from the mouth of hell,<br/>
All that was left of them,<br/>
Left of six hundred. 
VI<br/>
When can their glory fade?<br/>
O the wild charge they made!<br/>
All the world wondered. Honour the charge they made! Honour the Light Brigade,<br/>
Noble six hundred!<br/>
        </div>
        <div id="poem6">
        Our brains ache, in the merciless iced east winds that knife us... Wearied we keep awake because the night is silent...<br/>
Low drooping flares confuse our memory of the salient... Worried by silence, sentries whisper, curious, nervous,<br/>
But nothing happens.<br/>
Watching, we hear the mad gusts tugging on the wire. Like twitching agonies of men among its brambles. <br/>
Northward incessantly, the flickering gunnery rumbles, Far off, like a dull rumour of some other war.<br/>
What are we doing here?<br/>
The poignant misery of dawn begins to grow...<br/>
We only know war lasts, rain soaks, and clouds sag stormy. Dawn massing in the east her melancholy army<br/>
Attacks once more in ranks on shivering ranks of gray,<br/>
But nothing happens.<br/>
Sudden successive flights of bullets streak the silence.<br/>
Less deadly than the air that shudders black with snow,<br/>
With sidelong flowing flakes that flock, pause and renew,<br/>
We watch them wandering up and down the wind's nonchalance,<br/>
But nothing happens.<br/>
Pale flakes with lingering stealth come feeling for our faces -<br/>
We cringe in holes, back on forgotten dreams, and stare, snow-dazed, Deep into grassier ditches. So we drowse, sun-dozed,<br/>
Littered with blossoms trickling where the blackbird fusses.<br/>
Slowly our ghosts drag home: glimpsing the sunk fires glozed With crusted dark-red jewels; crickets jingle there;<br/>
For hours the innocent mice rejoice: the house is theirs; Shutters and doors all closed: on us the doors are closed -<br/>
We turn back to our dying.<br/>
Since we believe not otherwise can kind fires burn;<br/>
Now ever suns smile true on child, or field, or fruit.<br/>
For God's invincible spring our love is made afraid; Therefore, not loath, we lie out here; therefore were born,<br/>
For love of God seems dying.<br/>
To-night, His frost will fasten on this mud and us, Shrivelling many hands and puckering foreheads crisp.<br/>
The burying-party, picks and shovels in their shaking grasp, Pause over half-known faces. All their eyes are ice,<br/>
But nothing happens.<br/>

        </div>
        <div id="poem7">
        We are prepared: we build our houses squat,<br/>
Sink walls in rock and roof them with good slate. The wizened earth had never troubled us<br/>
With hay, so as you can see, there are no stacks Or stooks that can be lost. <br/>
Nor are there trees Which might prove company when it blows full Blast: you know what I mean - leaves and branches Can raise a chorus in a gale<br/>
So that you can listen to the thing you fear Forgetting that it pummels your house too.<br/>
But there are no trees, no natural shelter.<br/>
You might think that the sea is company, Exploding comfortably down on the cliffs<br/>
But no: when it begins, the flung spray hits<br/>
The very windows, spits like a tame cat<br/>
Turned savage.We just sit tight while wind dives And strafes invisibly. Space is a salvo.<br/>
We are bombarded by the empty air.<br/>
Strange, it is a huge nothing that we fear.<br/>
        </div>
        <div id="poem8">
        Suddenly he awoke and was running- raw<br/>
In raw-seamed hot khaki, his sweat heavy,<br/>
Stumbling across a field of clods towards a green hedge That dazzled with rifle fire, hearing<br/>
Bullets smacking the belly out of the air -<br/>
He lugged a rifle numb as a smashed arm;<br/>
The patriotic tear that had brimmed in his eye Sweating like molten iron from the centre of his chest, -<br/>
In bewilderment then he almost stopped -<br/>
In what cold clockwork of the stars and the nations Was he the hand pointing that second? <br/>
He was running Like a man who has jumped up in the dark and runs Listening between his footfalls for the reason<br/>
Of his still running, and his foot hung like<br/>
Statuary in mid-stride.Then the shot-slashed furrows<br/>
Threw up a yellow hare that rolled like a flame<br/>
And crawled in a threshing circle, its mouth wide<br/>
Open silent, its eyes standing out.<br/>
He plunged past with his bayonet toward the green hedge, King, honour, human dignity, etcetera<br/>
Dropped like luxuries in a yelling alarm To get out of that blue crackling air His terror’s touchy dynamite.<br/>
        </div>
        <div id="poem9">
        On another occasion, we got sent out to tackle looters raiding a bank.<br/>
And one of them legs it up the road, probably armed, possibly not.<br/>
Well myself and somebody else and somebody else are all of the same mind,<br/>
so all three of us open fire.<br/>
Three of a kind all letting fly, and I swear<br/>
I see every round as it rips through his life –<br/>
I see broad daylight on the other side.<br/>
So we’ve hit this looter a dozen times<br/>
and he’s there on the ground, sort of inside out,<br/>
pain itself, the image of agony.<br/>
One of my mates goes by<br/>
and tosses his guts back into his body. Then he’s carted off in the back of a lorry.<br/>
End of story, except not really.<br/>
His blood-shadow stays on the street, and out on patrol I walk right over it week after week.<br/>
Then I’m home on leave. But I blink<br/>
and he bursts again through the doors of the bank. Sleep, and he’s probably armed, and possibly not. <br/>
Dream, and he’s torn apart by a dozen rounds. And the drink and the drugs won’t flush him out –<br/>
he’s here in my head when I close my eyes,<br/>
dug in behind enemy lines,<br/>
not left for dead in some distant, sun-stunned, sand- smothered land<br/>
or six-feet-under in desert sand,<br/>
but near to the knuckle, here and now, his bloody life in my bloody hands.<br/>
        </div>
        <div id="poem10">
        Three days before Armistice Sunday<br/>
and poppies had already been placed<br/>
on individual war graves. Before you left,<br/>
I pinned one onto your lapel, crimped petals, spasms of paper red, disrupting a blockade of yellow bias binding around your blazer.<br/>
Sellotape bandaged around my hand, I rounded up as many white cat hairs as I could, <br/>
smoothed down your shirt's upturned collar, steeled the softening of my face. <br/>
I wanted to graze my nose across the tip of your nose, play at being Eskimos like we did when<br/>
you were little. I resisted the impulse to run my fingers through the gelled blackthorns of your hair.All my words flattened, rolled, turned into felt,<br/>
slowly melting. I was brave, as I walked with you, to the front door, threw<br/>
it open, the world overflowing<br/>
like a treasure chest.A split second and you were away, intoxicated.<br/>
After you'd gone I went into your bedroom, released a song bird from its cage.<br/>
Later a single dove flew from the pear tree, and this is where it has led me,<br/>
skirting the church yard walls, my stomach busy making tucks, darts, pleats, hat-less, without<br/>
a winter coat or reinforcements of scarf, gloves.<br/>
On reaching the top of the hill I traced<br/>
the inscriptions on the war memorial,<br/>
leaned against it like a wishbone.<br/>
The dove pulled freely against the sky,<br/>
an ornamental stitch. I listened, hoping to hear your playground voice catching on the wind.<br/>
        </div>
        <div id="poem11">
        In his dark room he is finally alone<br/>
with spools of suffering set out in ordered rows. The only light is red and softly glows,<br/>
as though this were a church and he<br/>
a priest preparing to intone a Mass.<br/>
Belfast. Beirut. Phnom Penh. All flesh is grass<br/>
He has a job to do. Solutions slop in trays beneath his hands, which did not tremble then though seem to now. <br/>
Rural England. Home again to ordinary pain which simple weather can dispel, to fields which don’t explode beneath the feet<br/>
of running children in a nightmare heat<br/>
Something is happening. A stranger’s features faintly start to twist before his eyes,<br/>
a half-formed ghost. He remembers the cries of this man’s wife, how he sought approval without words to do what someone must<br/>
and how the blood stained into foreign dust.<br/>
A hundred agonies in black and white<br/>
from which his editor will pick out five or six<br/>
for Sunday’s supplement. The reader’s eyeballs prick with tears between the bath and pre-lunch beers. <br/>
From the aeroplane he stares impassively at where he earns his living and they do not care<br/>
        </div>
        <div id="poem12">
        Paper that lets the light<br/>
shine through, this<br/>
is what could alter things.<br/>
Paper thinned by age or touching<br/>
the kind you find in well-used books, the back of the Koran, where a hand has written in the names and histories, who was born to whom,<br/>
the height and weight, who<br/>
died where and how, on which sepia date, pages smoothed and stroked and turned transparent with attention.<br/>
If buildings were paper, I might feel their drift, see how easily they fall away on a sigh, a shift in the direction of the wind.<br/>
Maps too. The sun shines through their borderlines, the marks<br/>
that rivers make, roads, railtracks, mountainfolds,<br/>
Fine slips from grocery shops<br/>
that say how much was sold<br/>
and what was paid by credit card might fly our lives like paper kites.<br/>
An architect could use all this,<br/>
place layer over layer, luminous<br/>
script over numbers over line,<br/>
and never wish to build again with brick<br/>
or block, but let the daylight break through capitals and monoliths,<br/>
through the shapes that pride can make, find a way to trace a grand design<br/>
with living tissue, raise a structure never meant to last,<br/>
of paper smoothed and stroked and thinned to be transparent,<br/>
turned into your skin.
        </div>
        <div id="poem13">
        There once was a country... I left it as a child but my memory of it is sunlight-clear<br/>
for it seems I never saw it in that November which, I am told, comes to the mildest city. <br/>
The worst news I receive of it cannot break my original view, the bright, filled paperweight. <br/>
It may be at war, it may be sick with tyrants, but I am branded by an impression of sunlight.<br/>
The white streets of that city, the graceful slopes glow even clearer as time rolls its tanks<br/>
and the frontiers rise between us, close like waves. That child’s vocabulary I carried here<br/>
like a hollow doll, opens and spills a grammar. Soon I shall have every coloured molecule of it.<br/>
It may by now be a lie, banned by the state<br/>
but I can’t get it off my tongue. It tastes of sunlight.<br/>
I have no passport, there’s no way back at all<br/>
but my city comes to me in its own white plane.<br/>
It lies down in front of me, docile as paper;<br/>
I comb its hair and love its shining eyes.<br/>
My city takes me dancing through the city<br/>
of walls. They accuse me of absence, they circle me. They accuse me of being dark in their free city.<br/>
My city hides behind me. They mutter death,<br/>
and my shadow falls as evidence of sunlight.<br/>
        </div>
        <div id="poem14">
        Dem tell me<br/>
Dem tell me<br/>
Wha dem want to tell me<br/>
Bandage up me eye with me own history Blind me to me own identity<br/>
Dem tell me bout 1066 and all dat<br/>
dem tell me bout Dick Whittington and he cat But Toussaint L’Ouverture<br/>
no dem never tell me bout dat<br/>
Toussaint a slave with vision lick back<br/>
Napoleon battalion and first Black Republic born<br/>
Toussaint de thorn to de French<br/>
Toussaint de beacon of de Haitian Revolution<br/>
Dem tell me bout de man who discover de balloon and de cow who jump over de moon<br/>
Dem tell me bout de dish ran away with de spoon but dem never tell me bout Nanny de maroon<br/>
Nanny see-far woman of mountain dream fire-woman struggle hopeful stream to freedom river<br/>
Dem tell me bout Lord Nelson and Waterloo<br/>
but dem never tell me bout Shaka de great Zulu Dem tell me bout Columbus and 1492<br/>
but what happen to de Caribs and de Arawaks too<br/>
Dem tell me bout Florence Nightingale and she lamp and how Robin Hood used to camp<br/>
Dem tell me bout ole King Cole was a merry ole soul but dem never tell me bout Mary Seacole<br/>
From Jamaica she travel far to the Crimean War she volunteer to go<br/>
and even when de British said no she still brave the Russian snow a healing star<br/>
among the wounded a yellow sunrise to the dying<br/>
Dem tell me Dem tell me wha dem want to tell me But now I checking out me own history I carving out me identity<br/>
        </div>
        <div id="poem15">
        Her father embarked at sunrise<br/>
with a flask of water, a samurai sword in the cockpit, a shaven head<br/>
full of powerful incantations<br/>
and enough fuel for a one-way journey into history<br/>
but half way there, she thought, recounting it later to her children, he must have looked far down<br/>
at the little fishing boats<br/>
strung out like bunting<br/>
on a green-blue translucent sea<br/>
and beneath them, arcing in swathes like a huge flag waved first one way then the other in a figure of eight, the dark shoals of fishes<br/>
flashing silver as their bellies swivelled towards the sun<br/>
and remembered how he<br/>
and his brothers waiting on the shore built cairns of pearl-grey pebbles<br/>
to see whose withstood longest<br/>
the turbulent inrush of breakers bringing their father’s boat safe<br/>
– yes, grandfather’s boat – safe<br/>
to the shore, salt-sodden, awash<br/>
with cloud-marked mackerel,<br/>
black crabs, feathery prawns,<br/>
the loose silver of whitebait and once<br/>
a tuna, the dark prince, muscular, dangerous.<br/>
And though he came back<br/>
my mother never spoke again<br/>
in his presence, nor did she meet his eyes and the neighbours too, they treated him as though he no longer existed,<br/>
only we children still chattered and laughed<br/>
till gradually we too learned<br/>
to be silent, to live as though<br/>
he had never returned, that this was no longer the father we loved.<br/>
And sometimes, she said, he must have wondered which had been the better way to die.<br/>
        </div>
        <div id="poem16"></div>
        </div>
        
      
      </div>
      <div className="content">
        {content}
      </div>
    </div>
  );
}

export default App;
